import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import { add_post, category_list, delete_list, edit_list, first_menulist } from "../../api/index";
export default {
  name: "basetable",
  data: function data() {
    return {
      //添加菜单数据
      addform: {
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status || 1,
        component: this.component,
        parent_id: this.parent_id,
        left_show: this.left_show
      },
      // 子菜单
      submenuform: {
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status || 1,
        component: this.component,
        parent_id: this.parent_id,
        left_show: this.left_show
      },
      // 编辑菜单
      editform: {
        editId: this.editId,
        name: this.name,
        title: this.title,
        path: this.path,
        icon: this.icon,
        status: this.status,
        component: this.component,
        parent_id: this.list_order,
        left_show: this.left_show,
        list_order: this.list_order
      },
      getmenulistArr: [],
      editId: '',
      hoteldatares: {
        page_index: 1,
        page_size: 5
      },
      rules: {
        name: [{
          required: true,
          message: "请输入标签名称、例：login",
          trigger: "blur"
        }],
        component: [{
          required: true,
          message: "请输入文件路径、例：views/index/index",
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "请输入导航名称、例：***管理",
          trigger: "blur"
        }],
        path: [{
          required: true,
          message: "请输入路由路径、例：login",
          trigger: "blur"
        }],
        parent_id: [{
          required: true,
          message: "请选择上级菜单",
          trigger: "change"
        }],
        icon: [{
          required: true,
          message: "请前往icon地址选择菜单icon、例：el-icon-phone-outline",
          trigger: "blur"
        }],
        status: [{
          required: true,
          message: "请选择菜单状态",
          trigger: "change"
        }],
        list_order: [{
          required: true,
          message: "请输入排序，此排序作为菜单显示顺序",
          trigger: "change"
        }],
        left_show: [{
          required: true,
          message: "请选择，此项目展示于左边菜单栏",
          trigger: "blur"
        }]
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      submenuVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this = this;

      category_list(this.hoteldatares).then(function (res) {
        _this.tableData = res.data.category;
        _this.pageTotal = res.data.count;
      });
    },
    getmenulist: function getmenulist() {
      var _this2 = this;

      first_menulist().then(function (res) {
        console.log(res);
        _this2.getmenulistArr = res.data;
      });
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id
        };
        delete_list(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this3.$message.success("删除成功");

            _this3.tableData.splice(index, 1);

            _this3.getData();
          } else {
            _this3.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    // 多选操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection: function delAllSelection() {
      var length = this.multipleSelection.length;
      var str = "";
      this.delList = this.delList.concat(this.multipleSelection);

      for (var i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }

      this.$message.error("\u5220\u9664\u4E86".concat(str));
      this.multipleSelection = [];
    },
    // 添加菜单
    addmenu: function addmenu() {
      this.addVisible = true;
      this.getmenulist();
    },
    // 添加子菜单
    submenuFun: function submenuFun() {
      var _this4 = this;

      this.submenuVisible = false;
      add_post(this.submenuform).then(function (res) {
        if (res.code == 1) {
          _this4.$message.success(res.msg);

          _this4.getData();
        } else {
          _this4.$message.error(res.error);
        }
      }); // this.$refs[submenuform].validate((valid) => {
      //   if (valid) {
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this5 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          add_post(_this5.addform).then(function (res) {
            if (res.code == 1) {
              _this5.addVisible = false;

              _this5.$message.success(res.msg);

              _this5.getData();
            } else {
              _this5.$message.error(res.error);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.editform = row;
      this.editform.editId = row.id;
      this.editVisible = true;
    },
    addsmall: function addsmall() {
      this.getmenulist();
      this.submenuVisible = true;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this6 = this;

      console.log(this.editform);
      edit_list(this.editform).then(function (res) {
        if (res.code == 1) {
          _this6.$message.success(res.msg);

          _this6.editVisible = false;

          _this6.getData();
        } else {
          _this6.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handleCurrentChange: function handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index); //点击第几页

      this.getData();
    }
  }
};